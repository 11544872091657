footer {
    background-color: black;
    padding: 0vw;
}



@media screen and (max-width: 600px) {
   footer {
    
    object-fit: cover;
    padding: 0vw;
   }


    }