.calendario-container_posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .centralizador-botao {
    display: flex;
    margin: 1rem;
    justify-content: center; /* Alinha os itens horizontalmente ao centro */
    align-items: center;
  }
  
  .calendario-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
  }

  .calendario-placar {
    display: flex;
    padding: 13px 20px 13px 20px;
    border-radius: 5px;
    background-color: orange; /* Adicione esta linha */

    margin: 0 5px;

  }
  
  .calendario-placar h2 {
    padding: 2px 2px;
    color: white; /* Altere esta linha */
    font-size: 16px !important; 
  }


  .calendario-button {
   
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 12px 25px;
    font-size: 16px;
    --tw-text-opacity: 1;
    color: rgba(107,114,128,var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(107,114,128,var(--tw-border-opacity));
    cursor: pointer;
    border-radius: 2rem;
  }
  
  .calendario-container_calendario {
    width: 80%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
  }
  

  .calendario-container_confronto h2 {
    font-size: 24px;
  }

  .calendario-container_confronto h2,
  .calendario-container_confronto p {
    text-align: center;
  }
  
  .calendario-confronto {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .calendario-confronto img {
    width: 60px;

  }
  
 
  
  /* Adicione este bloco de código */
  .calendario-confronto h2 {
    flex-basis: 25%; /* ajuste o valor conforme necessário */
  }
  
  .calendario-confronto h3 {
    flex-basis: 32%;
  }

  .calendario_btn .calendario {
    display: inline-block;
    padding: 10px 40px 10px 10px; /* Adiciona espaço à direita do ícone */
    border-radius: 5px;
    background-color: red; /* Altere para a cor desejada */
    color: #fff; /* Altere para a cor desejada */
    text-decoration: none;
 
   
    /* Adicione um ícone do YouTube ao lado do texto do botão */
    background-image: url('./icons8-youtube-30.png');
    background-repeat: no-repeat;
    background-position: right; 
    margin: 0 5px;
  }


  .btn-next, .btn-after {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    background-color: #007BFF; /* Altere para a cor desejada */
    color: #fff;
    text-decoration: none;
    text-align: center;
  }

/* Para tablets */
@media (max-width: 840px) {
  .calendario-container_calendario {
    width: 100%;
    height: auto;
  }

  .calendario-confronto {
    flex-direction: column;
    align-items: center; /* Alinha os itens verticalmente ao centro */
    
  }

  .calendario-confronto h2,
  .calendario-confronto h3 {
    font-size: 18px;
   line-height: 1.5rem;
    text-align: center; /* Centraliza o texto */
  }

  .calendario-confronto img {
    width: 100px;
    margin-bottom: 10px; /* Adiciona espaço abaixo da imagem */
  }

  .calendario-placar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

 
 .btn-next, .btn-after {
  padding: 8px 16px;
  font-size: 14px;
}
}
 
/* Para tablets */
@media (max-width: 768px) {
  .calendario-container_calendario {
    width: 100%;
    height: auto;
  }

  .calendario-confronto {
    flex-direction: column;
    align-items: center; /* Alinha os itens verticalmente ao centro */
    
  }

  .calendario-confronto h2,
  .calendario-confronto h3 {
    font-size: 18px;
   line-height: 1.5rem;
    text-align: center; /* Centraliza o texto */
  }

  .calendario-confronto img {
    width: 100px;
    margin-bottom: 10px; /* Adiciona espaço abaixo da imagem */
  }

  .calendario-placar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

 /* Estilo específico para o botão 'ver jogo' */
 .calendario_btn .calendario {
   display: inline-block;
   padding: 10px 60px 10px 20px; /* Adiciona espaço à direita do ícone */
   border-radius: 5px;
   color: #fff; /* Altere para a cor desejada */
   text-decoration: none;
   text-align: center;

   /* Adicione um ícone do YouTube ao lado do texto do botão */
   background-image: url('./icons8-youtube.svg');
   background-repeat: no-repeat;
   background-position: right; 
 }
 .btn-next, .btn-after {
  padding: 8px 16px;
  font-size: 14px;
}
}
  /* Para dispositivos móveis */
  @media (max-width: 480px) {
      .calendario-container_calendario {
        width: 100%;
        height: auto;
      }
    
      .calendario-confronto {
        flex-direction: column;
        align-items: center; /* Alinha os itens verticalmente ao centro */
        
      }
    
      .calendario-confronto h2,
      .calendario-confronto h3 {
        font-size: 18px;
       line-height: 1.5rem;
        text-align: center; /* Centraliza o texto */
      }
    
      .calendario-confronto img {
        width: 70px;
        margin-bottom: 10px; /* Adiciona espaço abaixo da imagem */
      }
    
      .calendario-placar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
  
     /* Estilo específico para o botão 'ver jogo' */
     .calendario_btn .calendario {
       display: inline-block;
       padding: 10px 60px 10px 20px; /* Adiciona espaço à direita do ícone */
       border-radius: 5px;
       color: #fff; /* Altere para a cor desejada */
       text-decoration: none;
       text-align: center;
  
       /* Adicione um ícone do YouTube ao lado do texto do botão */
       background-image: url('./icons8-youtube.svg');
       background-repeat: no-repeat;
       background-position: right; 
     }
  }