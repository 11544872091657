.associacao_hr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
    font-size: 32px;
    position: relative; /* Adicionado position: relative */
  }
  
  .associacao_hr::before {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: orange;
    position: absolute;
    left: 10px; /* Ajustado para a barra estar mais próxima ao texto */
    top: 0; /* Posição fixa no topo */
  }


  
  
  

  
  .associacao_titulo {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .associacao_texto {
    margin-bottom: 2em;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 2px;
  }
  
  .associacao_objetivos {
    margin: 3em 0;
   
  }
  
  .associacao_objetivos h1 {
    margin-bottom: 1em;
    text-align: center;
    font-size: 32px;
  }
  
  .associacao_objetivos ul {
    list-style-type: none;
    padding-left: 0;
   
  }
  
  .associacao_objetivos li {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0.9em;
    letter-spacing: 2px;

  
  }
  
  .associacao_objetivos li::before {
    content: "-";
    margin-right: 0.5em;
  }
  

Footer {
    width: 100vw;
}