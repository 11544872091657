@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;500;700&display=swap');


/* Estilo da notícia principal */
.news-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1000px;

}

.news-details h2 {
  font-size: 32px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
}

.news-details p {
  color: #4a4a4a;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 1.35rem;
  line-height: 1.6;
  letter-spacing: .3px;
  margin-bottom: 30px;
}

.news-link-container {
  margin-top: 2em;
}

.news-link-container .news-link {
  display: inline-block;
  padding: 12px 24px;
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  text-decoration: none;
  border: none;
  border-radius: 1em;
  transition: background-color 0.3s ease;
}

.news-link-container .news-link:hover {
  background-color: #ff7f00; /* Laranja */
  color: #fff;
}

.news-image img{
  width: 90vw;
  height: 80vh;
}

/* Estilo das outras notícias */
.other-news {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin:  40px 0;
}

.other-news-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.news-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 2em;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.news-item:hover {
  transform: translateY(-5px); /* Movimenta o card para cima */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Cria sombra */
}

.news-item:hover {
  background-color: #f2f2f2;
}

.news-item-image img {
  width: 100%;
  height: 320px; /* Defina uma altura fixa desejada */
  object-fit: cover; /* Isso garante que a imagem cubra todo o espaço, preservando a proporção */
  border-radius: 2em 2em 0 0;
}

.news-item-details {
  padding: 20px; /* Adapte o valor conforme necessário */
}

.news-item-link-container {
  text-align: center;
  margin-top: auto;
}


.news-item-details .news-item-title {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.news-item-details .news-item-text {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1em;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limita para 3 linhas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-item-details .news-item-link-container {
  margin-top: auto;
}

.news-item-details .news-item-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.news-item-details .news-item-link:hover {
  background-color: #ff7f00; /* Laranja */
  color: #fff;
}

/* Estilo responsivo */
@media screen and (max-width: 768px) {
  /* Estilo da notícia principal */
  .news-details {
    align-items: center;
    width: 100%; /* Preenche toda a largura */
  }

  .news-details h2 {
    font-size: 1.5rem;
   
  }

  .news-image img {
    width: 100vw;
    height: auto;
    border-bottom: 2px solid black;
  }

  .news-details p {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-left: 0.4em;
    white-space: pre-line; /* Mantém quebras de linha */
    word-wrap: break-word; /* Quebra de palavra */
    overflow-wrap: break-word; /* Quebra de palavra */
  }

  /* Estilo das outras notícias */
  .other-news {
    grid-template-columns: 1fr;
  }
}

