.associado {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  form {
    width: 500px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  
  label {
    font-size: 14px;
    color: #444;
    display: block;
    margin-bottom: 5px;
  }
  
  input, select {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .button-form {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #5c6bc0;
    color: #fff;
    margin-bottom: 2rem;
  }

  .button-vantagens {
    display: inline-block;
    padding: 10px 20px;
    background-color: orange;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* adiciona uma transição suave ao mudar a cor de fundo */
    margin-bottom: 2rem;
  }
  
  .button-vantagens:hover {
    background-color: rgb(255, 145, 11); /* cor de fundo quando o mouse passa por cima */
  }
  
  

  .form-associado {
   display: flex;
   flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .form-associado h2 {
    margin-top: 1.5rem;
    font-size: 32px;
    font-family: 'Arial', sans-serif; /* Escolha a fonte que preferir */
    letter-spacing: 1px; /* Espaçamento entre as letras */
    text-transform: uppercase; /* Transforma todas as letras em maiúsculas */
}

.form-associado p {
    margin: 1rem 0rem;
}
.parceiro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.cards {
  width: calc(33.33% - 20px); /* largura para 3 cards por linha, considerando uma margem de 10px */
  margin: 10px; /* margem ao redor dos cards */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc; /* borda acinzentada */ 
  border-radius: 1rem;
 

}

.cards h3 {

  font-size: 2rem;

}

.cards img {
  
  width: 100%; /* faz a imagem ocupar 80% da largura do card */
  border-radius: 1rem;
  margin: auto; /* centraliza a imagem */
  object-fit: cover; /* faz com que a imagem cubra toda a área do elemento pai, mantendo sua proporção */
}

@media (min-width: 769px) and (max-width: 1024px) {
    .form-associado {
      padding: 20px;
    }
  
    form {
      width: 80%;
    }
  
    input, select {
      height: 50px;
      font-size: 18px;
    }
  
    button {
      height: 50px;
      font-size: 18px;
    }
  }
  
  /* Estilos para celulares */
  @media (max-width: 768px) {
    .form-associado {
      padding: 10px;
    }
  
    form {
      width: 80%;
     
    }
  
    input, select {
      height: 40px;
      font-size: 16px;
    }
  
    button {
      height: 40px;
      font-size: 16px;
    }
    .parceiro {
      display: flex;
      align-items: center;
      justify-content: center
      ;
    }
    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%; /* faz os cards ocuparem toda a largura em telas pequenas */
    }
  }