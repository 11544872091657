html {
  scroll-behavior: smooth;
}

.container-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #eda120;
  height: 20vh;

  margin: 0em 0em -1em 0em;
}

.header-sociais {
  display: flex;
  gap: 2rem;
}

.header-sociais a {
  list-style: none;
  text-decoration: none;
  color: white;
  font-size: 32px;
}

header img {
  z-index: 10;
  width: 9%;
}

nav ul {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #1d489d;
  align-items: center;
  font-size: 20px;
  height: 6vh;
  
}

nav ul li a {
  border-left: 1px solid #eda120;
  border-right: 1px solid #eda120;
  padding: 0 2rem;
  list-style: none;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
}

nav ul li a:hover {
    color: black;
}

@media (max-width: 600px) {
  header img {
  }

  .header-sociais {
    display: flex;
    gap: 0.7rem;
  }

  nav {
    display: none;
  }
}

.btn-associado {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1d489d;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-associado:hover {
  background-color: #4051b5;
}

.vantagens-titulo {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .btn-associado {
      width: 30%;
      text-align: center;
      padding: 10px 20px; /* Reduzir o padding pode diminuir o tamanho do botão */
  }
  header img {
    width: 10rem;
  }

  .header-sociais {
    display: flex;
    gap: 0.7rem;
  }
}


@media (min-width: 769px) and (max-width: 900px) {
  header img {
    width: 15rem;
  }

  .header-sociais {
    display: flex;
    gap: 0.7rem;
  }

  nav {
    display: none;
  }
  
  .btn-associado {
    width: 30%;
    text-align: center;
    padding: 10px 20px; /* Reduzir o padding pode diminuir o tamanho do botão */
    font-size: 24px;
}
}


@media screen and (max-width: 1440px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .navbar-logo {
    order: 2;
    margin-bottom: 1rem;
  }
}

