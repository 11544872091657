@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;


}

section {
  margin: 5rem 0;
  height: auto;
  width: 100vw;
  padding: 0 10vw;
  
}

.ajuste {
  align-items: center;
  justify-content: center;
  
}


body {
  width: 100vw;
  height: 100vh;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  /*background-image: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.1)), url('./volei26set.jpg');
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  background-size: cover;*/
}

.ajuste_container_app {
  
   
  width: 100vw;
  padding: 0 10vw;
}


.ajuste_container_app hr {
  margin-bottom: 2em;
}




.btn {
  font-style: inherit;
  color: orange;
  border-radius: 1rem;
  border: 2px solid orange;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.btn:hover {
  border: 2px solid orange;
  color: blue;
  background-color: transparent;
  transform: scale(1.1);
  padding: 1rem;
}
