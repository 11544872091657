.ajuste_container {
    margin-top: 5rem;
   
    width: 100vw;
    padding: 0 10vw;
  
}
.container_noticias{
    text-align: center;
    width: 100;
    border-radius: 0em 0em 2em 2em;
    border: 0.5px solid #777777;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

}

.container_noticias:hover {
  transform: translateY(-5px); /* Movimenta o card para cima */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Cria sombra */
}

.container_noticias p {
  color: #4a4a4a;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 1.15rem;
  line-height: 1.6;
  letter-spacing: .3px;
 
}


.container_noticias h2 {
  padding: 1em;
  letter-spacing: .3px;
}

.swiper {
  width: 100%;
}
.portofolio__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


.portofolio__item-img {
  width: 100%;
  height: 500px;
  overflow: hidden;
  transition: transform 0.3s ease; /* Adiciona transição de transformação */
}

.portofolio__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Adiciona transição de transformação */
}

.container_noticias:hover .portofolio__item-img img {
  transform: scale(1.05); /* Aplica um zoom na imagem */
}



.portofolio__item-cta {
    padding: 2em;
}

.swiper-pagination {
  bottom: 20px; 
}

@media (max-width: 480px) {
  .portofolio__item-img {
    height: 350px;
  }
  .container_noticias h2 {
    padding: 1em;
    letter-spacing: .3px;
    font-size: 1.2rem;
  }
}
  
