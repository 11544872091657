.album-container {
  width: 100vw;
  padding: 0 10vw;
  margin-top: 2em;
}

.year-list {
  margin-bottom: 20px;
}

.year-button {
  display: inline-block;
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.year-button.selected {
  background-color: #ccc;
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.photo-item {
  width: 50%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.photo-item:hover,
.photo-item:focus,
.photo-item:active {
  transform: scale(1.1);
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .photo-grid {
    flex-direction: column;
  }
  
  .photo-item {
    width: 100%;
  }
}
